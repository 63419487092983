<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent scrollable width="100vw">
            <v-card height="80vh">
                <v-card-title class="white--text primary">Política de Privacidade</v-card-title>
                <v-card-text style="background: white">
                    <REV01 />
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="success" @click="save">
                        <v-icon dark left>check_circle</v-icon>
                        Aceitar os termos
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import REV01 from "@/components/shared/Privacy-rev01";

export default {
    data() {
        return {
            dialog: true,
        };
    },

    components: {
        REV01,
    },

    methods: {
        save() {
            // $store.getters.menuMode == 'USER'
            this.$store.commit("ACCEPT_PRIVACY");
            this.dialog = false;
        },
    },
};
</script>