<template>
    <v-dialog v-model="show" @keydown.esc="close" @keydown.enter="close" max-width="600">
        <ToolBarModal />
        <v-card class="fill-height pt-2" fluid>
            <Register @close="close" v-if="mode == 'REGISTER'" />
            <Form @close="close" @goToRecovery="goToRecovery" :email="email" v-else-if="mode == 'LOGIN'" />
            <Recovery @close="close" :email="email" v-else-if="mode == 'RECOVERY'" />
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "./Form.vue";
import Register from "./Register.vue";
import Recovery from "./Recovery.vue";
import ToolBarModal from "@/components/core/ToolBarModal.vue";

export default {
    name: "Login",

    components: { Form, Register, Recovery, ToolBarModal },

    mounted() {
        this.$eventHub.$on("openLogin", () => {
            this.mode = "LOGIN";
            this.show = true;
        });
        this.$eventHub.$on("openRegister", () => {
            this.mode = "REGISTER";
            this.show = true;
        });
        this.$eventHub.$on("openRecovery", () => {
            this.mode = "RECOVERY";
            this.show = true;
        });
    },

    data() {
        return {
            show: false,
            mode: "LOGIN",
            email: ""
        };
    },

    methods: {
        close() {
            this.show = false;
        },
        goToRecovery(email) {
            this.email = email;
            this.mode = "RECOVERY";
        },
    }
};
</script>