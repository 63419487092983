<template>
    <div v-if="charger">
        <div v-if="online == false">
            <v-alert :icon="$vuetify.breakpoint.xs ? '' : 'error'" prominent text :type="$vuetify.breakpoint.xs ? '' : 'error'" color="error" border="top">
                <h2>Offline</h2>
                <br>
                Desculpe pelo transtorno, estamos com problema de internet no eletroposto.
                <br>
                <br>
                <v-btn tile style="width: 100%" color="black white--text" :href="problemHref" target="_blank">
                    <v-icon left> fab fa-whatsapp </v-icon> Reportar Problema
                </v-btn>
            </v-alert>
        </div>
        <div v-else>
            <div class="mx-4" v-if="charger.numberPlugs > 1">
                <v-layout wrap v-if="selectedPlug">
                    <v-flex xs12 sm6 class="mb-0 pb-0 mb-sm-2 pb-sm-2">
                        <h2>Selecionado plug {{ selectedPlug }}</h2>
                    </v-flex>
                    <v-flex xs12 sm6 v-if="selectedPlug" class="mt-0 pt-0 mt-sm-2 pt-sm-2">
                        <v-btn style="width: 100%" small color="secondary" @click="resetScreen">
                            <v-icon left> list </v-icon>Listar todos Plugs
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                    <v-flex xs12 class="mb-0 pb-0 mb-sm-2 pb-sm-2">
                        <h2>Selecione o plug:</h2>
                    </v-flex>
                </v-layout>
            </div>
            <v-card-text>
                <div v-if="plugs != null">
                    <div v-for="plug of plugs" :key="plug.plug" class="mb-2">
                        <div v-if="selectedPlug != null">
                            <Plug :chargerName="chargerName" :charger="charger" :plug="plug" />
                        </div>
                        <div v-else>
                            <PlugSimple :plug="plug" @selectPlug="selectPlug" />
                        </div>
                    </div>
                    <!-- <v-alert outlined color="info" class="mt-sm-4" prominent border="top" icon="school" v-if="charger.numberPlugs > 1 && selectedPlug == null">
                        Estamos muito felizes por você estar utilizando nosso carregador.
                        <br>
                        <br>
                        <div class="title">Dica:</div>
                        Selecione o plug que você deseja utilizar.
                    </v-alert> -->
                </div>
                <v-card dark color="primary" v-else>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="headline">Aguarde : )</v-card-title>
                            <v-card-subtitle>Buscando informações...</v-card-subtitle>
                        </div>
                        <v-avatar class="ma-3" size="50" tile>
                            <v-progress-circular size="50" width="10" color="grey" indeterminate />
                        </v-avatar>
                    </div>
                </v-card>
            </v-card-text>
        </div>
    </div>
</template>

<script>
import Plug from "./Plug";
import PlugSimple from "./PlugSimple";

export default {
    props: {
        chargerName: {
            type: String,
            required: true,
        },
        charger: {
            required: true,
        },
    },

    components: { Plug, PlugSimple },

    mounted() {
        if (this.charger.numberPlugs == 1) {
            this.selectPlug(1);
        } else if (this.$route.params.plug) {
            this.selectPlug(this.$route.params.plug);
        } else {
            this.update();
        }
        this.timer = setInterval(this.update, 5000);
    },

    data() {
        return {
            plugs: null,
            timer: null,
            selectedPlug: null,
            online: false,
        };
    },

    computed: {
        problemHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Estou com problema para utilizar o carregador ${this.chargerName}.`);
        },
    },

    methods: {
        selectPlug(plugNumber) {
            if (plugNumber <= this.charger.numberPlugs) {
                this.selectedPlug = plugNumber;
                this.plugs = null;
                this.update();
            }
        },
        resetScreen() {
            this.selectedPlug = null;
            this.plugs = null;
            this.update();
        },
        update() {
            this.$http
                .get(this.selectedPlug ? `api/v2/now/${this.chargerName}/${this.selectedPlug}` : `api/v2/now/${this.chargerName}`, { continuous: true })
                .then((result) => {
                    if (result) {
                        let online = true;
                        for (let i = 0; i < result.length; i = i + 1) {
                            if (result[i].online == 0) {
                                online = false;
                                break;
                            }
                        }
                        this.online = online;
                        this.plugs = result;
                    } else {
                        this.plugs = null;
                    }
                })
                .catch(() => {
                    this.plugs = null;
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
