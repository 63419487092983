<template>
    <v-card-text class="pt-4">
        <v-form v-model="valid" ref="form" @submit.prevent="login">
            <v-text-field type="email" label="Digite o usuário" v-model="input.email" :rules="[rules.required, rules.email]" required />
            <v-text-field ref="password" label="Digite a senha" v-model="input.password" :rules="[rules.required]" :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" required @keyup.enter="login" />
            <v-switch v-model="input.keep" inset label="Manter logado" />
            <v-row align="center" justify="center" align-content="space-between">
                <v-col cols="auto" class="mr-auto">
                    <v-btn :loading="loading" :disabled="loading || !valid" v-on:click="login" :class="{ 'success white--text': valid }" :small="$vuetify.breakpoint.smAndDown"
                        width="45%">
                        Entrar
                    </v-btn>
                    <v-btn color="error" class="mx-sm-1" :small="$vuetify.breakpoint.smAndDown" @click="close" width="45%">Fechar</v-btn>
                </v-col>
                <v-col cols="auto" class="ml-auto">
                    <v-btn text small right class="text-body-2" style="text-transform: none" @click="goToRecovery">Esqueceu a senha?</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card-text>
</template>

<script>
import rules from "@/helpers/rules";

export default {
    name: "Form",

    props: {
        email: {
            type: String,
        },
    },

    mounted() {
        this.input.email = this.email;
        if (this.email) {
            this.$refs.password.focus();
        }
    },

    data() {
        return {
            rules,
            loading: false,
            valid: false,
            show1: false,
            input: {
                email: "",
                password: "",
                keep: true,
                notification: this.$store.getters.getNotification,
            },
        };
    },

    methods: {
        goToRecovery() {
            this.$emit('goToRecovery', this.input.email);
        },
        close() {
            this.$emit("close");
        },
        login() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http
                    .post("api/v2/auth", this.input)
                    .then((response) => {
                        this.$store.commit("SET_LOGIN", {
                            response,
                            email: this.input.email,
                            password: this.input.keep ? this.input.password : null,
                            remember: this.input.keep,
                        });

                        this.loading = false;
                        this.close();
                        this.$eventHub.$emit("makeLogin");
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Usuário e/ou Senha incorretos.");
                        this.loading = false;
                    });
            } else {
                this.$eventHub.$emit("msgError", "Digite usuário e senha valido.");
            }
        },
    }
};
</script>