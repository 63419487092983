<template>
    <!-- <div style="height: 0px; position: relative">
        <v-btn absolute small tile color="primary" style="top: 10px; right: 85px" class="elevation-24" @click="register">Cadastre-se</v-btn>

        <v-btn absolute small tile color="success" style="top: 10px; right: 5px" class="white--text elevation-24" @click="open">Entrar</v-btn>
    </div> -->
    <div>
        <v-btn color="primary" fixed dark outlined :small="$vuetify.breakpoint.xs" :text="false" right top style="background-color: white; top: 5px; right: 105px"
            class="elevation-24" @click="register">
            Cadastre-se
        </v-btn>
        <v-btn color="success" dark fixed right :small="$vuetify.breakpoint.xs" top style="top: 5px; right: 5px" class="elevation-24" @click="open">
            Entrar
        </v-btn>
    </div>
</template>

<script>
export default {
    methods: {
        open() {
            this.$eventHub.$emit("openLogin");
        },
        register() {
            this.$eventHub.$emit("openRegister");
        }
    }
};
</script>