<template>
    <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on" fixed right top style="top: 4px; right: 10px" class="elevation-24">
                    <v-icon>menu</v-icon>
                </v-btn>
            </template>

            <v-card class="overflow-y-auto" max-height="100vh">
                <v-card-text>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <img height="32" :src="require('@/assets/images/icon.png')" />
                            </v-list-item-avatar>
                            <v-list-item-content> </v-list-item-content>

                            <v-list-item-action>
                                <v-btn class="grey--text" icon @click="menu = false">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="title">Olá, {{ this.$store.state.user.name }}!!!</v-list-item-title>
                                <v-list-item-subtitle>{{ this.$store.state.login.email }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <router-link to="/" tag="v-btn">
                                    <v-btn class="red--text elevation-12 mb-2" icon @click="menu = false">
                                        <v-icon>home</v-icon>
                                    </v-btn>
                                </router-link>
                                <v-btn class="red--text elevation-12 mt-2" icon @click="$eventHub.$emit('openSettings')">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider />

                <v-card class="overflow-y-auto" :max-height="menuHeight">
                    <div v-if="$store.getters.menuMode == 'USER' && verifyRole('ROLE_USER')">
                        <v-list dense v-for="(item, i) in getMenus('ROLE_USER')" :key="i">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </div>

                    <div v-if="$store.getters.menuMode == 'USER' && verifyRole('ROLE_ADMIN_CONDOMINIUM')">
                        <v-divider />
                        <v-subheader>Gestão de condominio</v-subheader>
                        <v-list dense v-for="(item, i) in getMenus('ROLE_ADMIN_CONDOMINIUM')" :key="i">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </div>

                    <div v-if="$store.getters.menuMode == 'USER' && verifyRole('ROLE_CLIENT')">
                        <v-divider />
                        <v-subheader>Parceiro Carro Elétrico</v-subheader>
                        <v-list dense v-for="(item, i) in getMenus('ROLE_CLIENT')" :key="i">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </div>

                    <div v-if="$store.getters.menuMode == 'USER' && verifyRole('ROLE_CLIENT_BIKE')">
                        <v-divider />
                        <v-subheader>Parceiro Bicicleta Elétrica</v-subheader>
                        <v-list dense v-for="(item, i) in getMenus('ROLE_CLIENT_BIKE')" :key="i">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </div>

                    <div v-if="$store.getters.menuMode == 'OPERATOR' && verifyRole('ROLE_OPERATOR')">
                        <v-divider />
                        <v-subheader>Operador INCHARGE</v-subheader>
                        <v-list dense v-for="(item, i) in getMenus('ROLE_OPERATOR')" :key="i">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </div>

                    <div v-if="$store.getters.menuMode == 'ADMIN' && verifyRole('ROLE_ADMIN')">
                        <v-divider />
                        <v-subheader>Administrador INCHARGE</v-subheader>
                        <v-list dense v-for="(item, i) in getMenus('ROLE_ADMIN')" :key="i" class="pa-1">
                            <router-link :to="{ path: item.path }" tag="v-list-item">
                                <v-list-item @click="menu = false">
                                    <v-list-item-icon v-if="item.meta.menu.icon">
                                        <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                        <v-list dense>
                            <!-- <v-list-group :value="false" no-action sub-group>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>Outros</v-list-item-title>
                                    </v-list-item-content>
                                </template> -->
                            <v-list dense v-for="(item, i) in getSubmenus('ROLE_ADMIN')" :key="i" class="pa-0">
                                <router-link :to="{ path: item.path }" tag="v-list-item">
                                    <v-list-item @click="menu = false">
                                        <v-list-item-icon v-if="item.meta.menu.icon">
                                            <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list>
                            <!-- </v-list-group> -->
                        </v-list>
                    </div>
                </v-card>

                <!-- <v-divider/> -->

                <v-card-actions>
                    <v-btn-toggle v-if="verifyRole('ROLE_ADMIN') || verifyRole('ROLE_OPERATOR')" variant="elevated" color="primary" v-model="menuMode" mandatory divided>
                        <v-btn small value="USER">USER</v-btn>
                        <v-btn small value="OPERATOR" v-if="verifyRole('ROLE_OPERATOR')">OPER</v-btn>
                        <v-btn small value="ADMIN" v-if="verifyRole('ROLE_ADMIN')">ADMIN</v-btn>
                    </v-btn-toggle>
                    <v-spacer></v-spacer>
                    <v-btn dark color="primary" @click="logout"> <v-icon left dark>exit_to_app</v-icon>Logout </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Menu",
    data() {
        return {
            menu: false,
        };
    },
    methods: {
        logout() {
            this.$store.commit('CLEAR_LOGIN');
            this.$nextTick(() => {
                this.$emit('logout');
                this.$nextTick(() => {
                    this.menu = false;
                    this.$nextTick(() => {
                        document.location.reload();
                    });
                });
            });
        },
    },
    computed: {
        ...mapGetters(["verifyRole"]),
        menuHeight() {
            let size = this.$vuetify.breakpoint.height > 400 ? this.$vuetify.breakpoint.height - 300 : 100;
            return `${size}px`;
        },
        getMenus() {
            return (role) =>
                this.$router.options.routes.filter(
                    (route) => route.meta && route.meta.role == role && route.meta.menu && !route.meta.menu.level && (route.meta.menu.icon || route.meta.menu.title)
                );
        },
        getSubmenus() {
            return (role) =>
                this.$router.options.routes.filter(
                    (route) => route.meta && route.meta.role == role && route.meta.menu && route.meta.menu.level == 2 && (route.meta.menu.icon || route.meta.menu.title)
                );
        },
        menuMode: {
            get() {
                return this.$store.getters.menuMode
            },
            set(value) {
                this.$store.commit('CHANGE_MENU_MODE', value)
            }
        }
    },
};
</script>