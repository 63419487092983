export default {
    getComponentStyle(status) {
        let ret = { status: null, color: null, secondColor: null, icon: null }
        ret.status = this.getTranslated(status);
        if (status == "Charging") {
            ret.color = "error";
            ret.secondColor = "white";
            ret.icon = "battery_charging_full";
        } else if (status == "Available") {
            ret.color = "success";
            ret.secondColor = "white";
            ret.icon = "done_outline";
        } else if (status == "Faulted") {
            ret.color = "black";
            ret.secondColor = "white";
            ret.icon = "error";
        } else if (this.hasConnectedCarButNotCharging(status)) {
            ret.color = "warning";
            ret.secondColor = "secondary";
            ret.icon = "power";
        } else {
            ret.color = "black";
            ret.secondColor = "white";
            ret.icon = "error";
        }
        return ret;
    },
    getTranslated(status) {
        switch (status) {
            case 'Available':
                return "Disponível";
            case 'Charging':
                return "Carregando";
            case 'Preparing':
                return "Iniciando";
            case 'Finishing':
                return "Finalizando";
            case 'Unavailable':
                // return "Conectado";
                return "Indisponível";
            case 'Faulted':
                return "Falha Interna";
            case 'SuspendedEVSE':
            case 'SuspendedEV':
                return "Suspenso";
            default:
                return status;
        }
    },
    inStartStatus(status) {
        switch (status) {
            case 'Preparing':
            case 'Unavailable':
                return true;
        }
        return false
    },
    hasConnectedCarButNotCharging(status) {
        switch (status) {
            case 'Preparing':
            case 'SuspendedEVSE':
            case 'SuspendedEV':
            case 'Finishing':
            case 'Unavailable':
                return true;
        }
        return false
    },
    isOccupied(status) {
        switch (status) {
            case 'Charging':
            case 'Preparing':
            case 'SuspendedEVSE':
            case 'SuspendedEV':
            case 'Finishing':
            case 'Unavailable':
                return true;
        }
        return false
    },
};